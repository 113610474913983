<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <v-row>
          <v-col>
            <h3>درخواست جدید</h3>
          </v-col>
          <v-col>
            <v-dialog v-model="newPatientDialog" persistent width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="openNewPatient"
                  class="primary-btn"
                  style="float: left"
                >
                  <v-icon left> person_add </v-icon>
                  افزودن بیمار
                </v-btn>
              </template>
              <v-card class="cards pa-3">
                <v-card-title>
                  <h3>ثبت بیمار جدید</h3>
                </v-card-title>
                <v-card-text>
                  <div>
                    <v-text-field
                      label="نام بیمار"
                      v-model="newPatient.firstName"
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      label="نام‌خانوادگی بیمار"
                      v-model="newPatient.lastName"
                      outlined
                      dense
                    ></v-text-field>
                    <v-select
                      label="جنسیت"
                      v-model="newPatient.gender"
                      :items="genders"
                      item-text="text"
                      item-value="value"
                      outlined
                      dense
                    ></v-select>
                    <v-text-field
                      label="شماره موبایل"
                      v-model="newPatient.mobile"
                      type="number"
                      outlined
                      dense
                    ></v-text-field>
                    <!-- <v-text-field
                      label="شماره بیمه"
                      v-model="newPatient.insurNo"
                      outlined
                      dense
                    ></v-text-field> -->
                    <v-select
                      label="نوع بیمه"
                      v-model="newPatient.insurType"
                      :items="insurTypes"
                      item-text="text"
                      item-value="value"
                      outlined
                      dense
                    ></v-select>

                    <v-text-field
                      label="کد ملی"
                      v-model="newPatient.natCode"
                      type="number"
                      :rules="[isID]"
                      outlined
                      dense
                    ></v-text-field>
                    <v-row class="time-row">
                      <v-col class="pt-0">
                        <v-checkbox
                          v-model="newPatient.insuranceLastSheet"
                          color="primary"
                        >
                          <template v-slot:label>
                            <span class="mt-2">تا آخرین صفحه دفترچه</span>
                          </template>
                        </v-checkbox>
                        <v-text-field
                          outlined
                          dense
                          type="text"
                          v-model="newPatient.insuranceExpirationDate"
                          label="تاریخ انقضا دفترچه"
                          :editable="true"
                          class="date-input"
                          :disabled="newPatient.insuranceLastSheet"
                        >
                          <template #append>
                            <span id="addPatientinsuranceExpirationDate">
                              <v-icon class="calender-icon"
                                >mdi-calendar-blank</v-icon
                              >
                            </span>
                          </template>
                        </v-text-field>

                        <date-picker
                          v-model="newPatient.insuranceExpirationDate"
                          element="addPatientinsuranceExpirationDate"
                          :disabled="newPatient.insuranceLastSheet"
                          color="#00a7b7"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="red-btn"
                    outlined
                    @click="
                      newPatientDialog = false;
                      newPatient = {};
                    "
                  >
                    لغو
                  </v-btn>
                  <v-btn
                    class="primary-btn"
                    outlined
                    @click="submitNewPatient()"
                    :disabled="
                      newPatient.firstName == '' ||
                      newPatient.lastName == '' ||
                      newPatient.gender == '' ||
                      newPatient.mobile == '' ||
                      !checkNatCode(newPatient.natCode) ||
                      newPatient.insurType == ''
                    "
                  >
                    ثبت
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card class="cards pa-3">
        <v-card-text>
          <div>
            <v-row class="inputs-row">
              <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-text-field
                  label="نام"
                  v-model="filterName"
                  class="search-input"
                  dense
                  outlined
                  @keyup.enter.native="
                    (filterName != '' ||
                      filterNatCode != '' ||
                      filterCode != '') &&
                    !Busy
                      ? filterPatients()
                      : ''
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-text-field
                  type="number"
                  label="کدملی"
                  no-wheel
                  class="search-input"
                  dense
                  outlined
                  v-model="filterNatCode"
                  @keyup.enter.native="
                    (filterName != '' ||
                      filterNatCode != '' ||
                      filterCode != '') &&
                    !Busy
                      ? filterPatients()
                      : ''
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-text-field
                  type="number"
                  label="کد اشتراک"
                  no-wheel
                  class="search-input"
                  dense
                  outlined
                  v-model="filterCode"
                  @keyup.enter.native="
                    (filterName != '' ||
                      filterNatCode != '' ||
                      filterCode != '') &&
                    !Busy
                      ? filterPatients()
                      : ''
                  "
                ></v-text-field>
              </v-col>
              <v-btn
                class="secondary-btn mb-1 ms-3 me-3"
                @click="filterPatients()"
                :disabled="
                  (filterName == '' &&
                    filterNatCode == '' &&
                    filterCode == '') ||
                  Busy
                "
              >
                اعمال فیلتر
                <v-icon right>tune</v-icon>
              </v-btn>
            </v-row>
            <hr class="my-6" />
            <v-row class="time-row">
              <v-col>
                <v-progress-circular
                  v-if="Busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  v-if="!Busy"
                  v-model="patientName"
                  :items="patientNames"
                  @change="patientVisitsNumber()"
                  item-text="text"
                  item-value="id"
                  class="search-input"
                  dense
                  outlined
                  label="نام بیمار *"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-select
                  v-model="insurType"
                  :items="insurTypes"
                  item-text="text"
                  item-value="value"
                  label="نوع بیمه *"
                  menu-props="auto"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col class="pt-0">
                <v-checkbox
                  label="پزشک خارج از درمانگاه"
                  id="isOut"
                  color="primary"
                  v-model="isOut"
                  name="isOut"
                  class="outPatientCheckbox"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="!isOut">
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  v-if="!busy"
                  v-model="docId"
                  :items="drNames"
                  item-text="text"
                  item-value="id"
                  label="نام پزشک"
                  class="pa-0"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col v-if="isOut"
                ><v-text-field
                  v-model="outDoctor"
                  label="پزشک نویسنده نسخه"
                  outlined
                  dense
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row class="mt-0 time-row mb-2">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span>
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateDate"
                    label=" تاریخ  "
                    :editable="true"
                    class="date-input"
                    disabled
                  >
                  </v-text-field>
                </span>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateTime">
                  <v-text-field
                    outlined
                    dense
                    append-icon="schedule"
                    v-model="dateTime"
                    label=" ساعت "
                    :editable="true"
                    class="date-input"
                    disabled
                  >
                  </v-text-field>
                </span>
              </v-col>
            </v-row>

            <v-row class="time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <vuetify-money
                  v-model="price"
                  label="هزینه(﷼) *"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  type="number"
                  outlined
                  dense
                  style=""
                />
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                  outlined
                  dense
                  v-model="LaboratoryTurnNumber"
                  label=" شماره پذیرش آزمایش *"
                  :editable="true"
                  class="pa-0"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex" md="6" cols="12">
                <v-checkbox
                  v-model="insuranceLastSheet"
                  class="lastSheet-checkBox"
                  color="primary"
                  label="تا آخرین صفحه دفترچه"
                >
                </v-checkbox> </v-col
              ><v-col class="pt-0" md="6" cols="12">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="calendar_today"
                  v-model="insuranceExpirationDate"
                  label="تاریخ انقضا دفترچه"
                  :editable="true"
                  class="date-input mt-4"
                  :disabled="insuranceLastSheet"
                >
                  <template #append>
                    <span id="insuranceExpirationDate">
                      <v-icon class="calender-icon">mdi-calendar-blank</v-icon>
                    </span>
                  </template>
                </v-text-field>

                <date-picker
                  v-model="insuranceExpirationDate"
                  element="insuranceExpirationDate"
                  color="#00a7b7"
                  :disabled="insuranceLastSheet"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0">
                <v-text-field
                  outlined
                  dense
                  v-model="insurancePageNo"
                  label="شماره صفحه دفترچه"
                  :editable="true"
                  class="pa-0"
                  type="number"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-btn
              class="submit-btn primary-btn"
              @click="submit()"
              :disabled="
                patientName == '' ||
                insurType == '' ||
                LaboratoryTurnNumber == ''
              "
              >ثبت</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { mapGetters } from "vuex";
function checkCodeMeli(code) {
  var L = code.length;

  if (L < 8 || parseInt(code, 10) == 0) return false;
  code = ("0000" + code).substr(L + 4 - 10);
  if (parseInt(code.substr(3, 6), 10) == 0) return false;
  var c = parseInt(code.substr(9, 1), 10);
  var s = 0;
  for (var i = 0; i < 9; i++) s += parseInt(code.substr(i, 1), 10) * (10 - i);
  s = s % 11;
  return (s < 2 && c == s) || (s >= 2 && c == 11 - s);
}
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      busySubmit: true,
      newPatient: {
        firstName: "",
        lastName: "",
        gender: "",
        mobile: "",
        insurType: "",
        natCode: "",
        insuranceExpirationDate: "",
        insurNo: "",
        insuranceLastSheet: false,
      },
      price: "0",
      LaboratoryTurnNumber: "",
      date: moment(new Date()).format("jYYYY/jMM/jDD HH:MM"),
      dateDate: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTime: moment(new Date()).format("HH:mm"),
      patientName: "",
      patientVisitsTimes: "",
      visitsBusy: false,
      patientNames: [],
      insurType: "",
      newFilter: "",
      busy: false,
      Busy: false,
      isBusy: false,
      newPatientDialog: false,
      visitType: "",
      newItems: [],
      // reciept
      CurrentPage: 0,
      PerPage: 0,
      userService: "",
      reception: "",
      visitNo: "",
      docId: "",
      Fields: [
        { key: "name", label: "خدمت" },
        { key: "number", label: "تعداد" },
        { key: "cost", label: "قیمت" },
      ],
      Items: [],
      isMounted: false,
      // reciept
      genders: [
        { value: "زن", text: "زن" },
        { value: "مرد", text: "مرد" },
      ],
      drBusy: true,
      filterName: "",
      filterNatCode: "",
      filterCode: "",
      outDoctor: "",
      isOut: false,
      insuranceExpirationDate: "",
      insurancePageNo: "",
      insuranceLastSheet: false,
    };
  },
  methods: {
    isID(id) {
      // NOTE (m-isID) to check the validity of the national code
      return id
        ? checkCodeMeli(id)
          ? true
          : "کدملی وارد شده صحیح نمی‌باشد"
        : true;
    },
    checkNatCode(id) {
      return id ? (checkCodeMeli(id) ? true : false) : true;
    },
    openNewPatient() {
      (this.newPatient = {
        firstName: "",
        lastName: "",
        gender: "",
        mobile: "",
        insurType: "",
        natCode: "",
        insuranceExpirationDate: "",
        insurNo: "",
        insuranceLastSheet: false,
      }),
        (this.newPatientDialog = true);
    },
    submitNewPatient() {
      this.isBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/new",

          {
            gender: this.newPatient.gender,
            firstName: this.newPatient.firstName,
            lastName: this.newPatient.lastName,
            mobile: this.newPatient.mobile,
            insuranceId: this.newPatient.insurType,
            insurNo: this.newPatient.insurNo,
            natCode: this.newPatient.natCode,
            insuranceExpirationDate: this.newPatient.insuranceLastSheet
              ? ""
              : this.newPatient.insuranceExpirationDate,
            insuranceLastSheet: this.newPatient.insuranceLastSheet,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.filterName =
              this.newPatient.firstName + " " + this.newPatient.lastName;
            this.filterNatCode = this.newPatient.natCode;
            this.filterPatients();
            this.toast("بیمار با موفقیت ثبت شد.", "success");
            this.newPatient = {
              firstName: "",
              lastName: "",
              gender: "",
              mobile: "",
              insurType: "",
              natCode: "",
              insuranceExpirationDate: "",
              insurNo: "",
              insuranceLastSheet: false,
            };
            this.newPatientDialog = false;
            this.patientName = res.data;
            this.isBusy = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.isBusy = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
        });
    },
    patientVisitsNumber() {
      this.visitsBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/visitsNumber",

          {
            patientId: this.patientName,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.visitsBusy = false;
          if (res.status == 200) {
            this.patientVisitsTimes = res.data;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.visitsBusy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    filterPatients() {
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/filter",

          {
            name: this.filterName,
            natCode: this.filterNatCode || "",
            code: this.filterCode || "",
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.patientName = "";
            this.patientNames = res.data.map((x) => {
              return {
                id: x.id,
                text: x.name,
                insurance: x.insuranceId,
                nameW: x.nameW,
                code: x.code,
              };
            });
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    submit() {
      this.vLoading = true;
      this.busySubmit = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/visits/new",
          {
            patientId: this.patientName,
            insuranceId: this.insurType,
            docId: this.isOut === true ? "" : this.docId,
            date: this.dateDate + this.dateTime,
            LaboratoryTurnNumber: this.LaboratoryTurnNumber,
            outDoctor: this.outDoctor,
            isOut: this.isOut === true ? 1 : 0,
            insuranceExpirationDate: this.insuranceLastSheet
              ? ""
              : this.insuranceExpirationDate,
            insuranceLastSheet: this.insuranceLastSheet,

            insurancePageNo: this.insurancePageNo,

            totalCost:
              this.newItems.length != 0
                ? String(
                    this.newItems
                      .map((x) => x.patientCost)
                      .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                  )
                : this.price,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.vLoading = true;
          if (res.status == 201) {
            
            this.visitNo = res.data.visitNo;
            this.reception = this.userName;
            this.filterName = "";
            this.filterNatCode = "";
            this.toast("درخواست خدمت به ثبت رسید", "success");
            this.newPatient = "";
            this.isMounted = true;
            this.vLoading = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
            this.busySubmit = false;
          }
        })
        .then(() => {
          this.price = "0";
          this.date = moment(new Date()).format("jYYYY/jMM/jDD HH:MM");
          this.dateDate = moment(new Date()).format("jYYYY/jMM/jDD");
          this.dateTime = moment(new Date()).format("HH:mm");
          this.patientName = "";
          this.docId = "";
          this.LaboratoryTurnNumber = "";
          this.insurType = "";
          this.newFilter = "";
          this.outDoctor = "";
          this.insuranceExpirationDate = "";
          this.insurancePageNo = "";
          this.insuranceLastSheet = "";
          this.isOut = false;
          this.newPatient = {
            firstName: "",
            lastName: "",
            gender: "",
            mobile: "",
            insurType: "",
            natCode: "",
            insuranceExpirationDate: "",
            insurNo: "",
            insuranceLastSheet: false,
          };
          this.busy = false;
          this.newItems = [];
          // reciept
          this.CurrentPage = 0;
          this.PerPage = 0;
          this.reception = "";
          this.visitNo = "";

          this.Items = [];
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
          this.busySubmit = false;
        });
    },
  },
  computed: {
    ...mapGetters(["drNames", "insurTypes", "userName"]),
  },
};
</script>
<style lang="scss">
.infoMessage {
  text-align: center;
  border: 1px dotted black;
  padding: 10px 5px;
  font-size: 16px;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 83%;
}
</style>
